import _ from 'lodash'

import i18n from '../i18n'
import useFeatureCollection from '../hooks/useFeatureCollection'
import { useCollectionStore } from '../state/collectionStore'
import { useCommonStore } from '../state/commonStore'
import TemplateService from './TemplateService'
import IrriCommandService from '../services/IrriCommandService'

const TenantSettingsService = (apiClient?: any) => {

  const irriCommandService = IrriCommandService()
  const templateService: any = TemplateService('tenantSettings', 'tenantSettings', apiClient)
  const tenantSettings = useCollectionStore(state => state.tenantSettings ? state.tenantSettings[0] : state.tenantSettings)
  const Features = useFeatureCollection()
  const switches = Features.switches || []
  const assets = useCollectionStore(state => state.assets) ?? []
  const profile = useCommonStore(state => state.profile)

  /* default irrigation group settings */
  const defaultSettings: any  = {
    name: 'Unassigned_Station',
    pumpShutDownGracePeriod: 0,//minutes
    earlyCheckinGracePeriod: 0,//minutes
    iGraceWarning: 0,//minutes
    iLateLeavePeriod: 0,//minutes
    iMinUsersForPump: 1,//not used
    iMaxUsersForPump: 4,//not used
    serverUrl: "https://wrm-api.azurewebsites.net/irrigation/event"
  }
  /* mapping of irrigation group settings' names to plc 'SetSettings' command's property names */
  const groupSettingsToPlcSettings: any  = {
    name: 'name',
    pumpShutDownGracePeriod: 'iGracePeriod',
    earlyCheckinGracePeriod: 'iEarlyArrivalPeriod',
    iGraceWarning: 'iGraceWarning',
    iLateLeavePeriod: 'iLateLeavePeriod',
    iMinUsersForPump: 'iMinUsersForPump',
    iMaxUsersForPump: 'iMaxUsersForPump',
    serverUrl: 'serverUrl',
  }

  /**
  * Saves (either adds a new or updates an existing one) a given group of irrigation settings
  * and sends appropriate 'setSettings' commands to the affected irrigation station controllers.
  * The affected controllers are not only the ones stated by the group.irrigationPointCodes field,
  * but also, in case of updating an existing group, any irrigation stations controllers that were previously (but not anymore)
  * included in this group.
  * For each affected irrigation station controller, the function:
  * retrieves (by sending a 'GetSettings' command) its current settings,
  * updates the specific setting, stated by the group.type field,
  * and then sends a 'SetSettings' command with the updated settings.
  * @param group - the given group of irrigation settings.
  * @todo Maybe we need a preconfigured set of default settings (which an operator may modify),
  * so that all irrigation station controllers have a complete set of settings, even if not stated in any group settings.
  * @todo Maybe an irrigation station should be, optionally, included in only one settings group
  * and such a group should specify at least one (and up to all) setting that can be set on an irrigation station.
  */
  const saveIrrigationGroup = async (group: any) => {

    const newIrrigationPointCodes: string[] = group.irrigationPointCodes

    let existingGroup: any = tenantSettings?.irrigation.groups.find(g => g._id === group._id)

    let finalSettings: any = _.cloneDeep(tenantSettings)
    if (existingGroup) {
      //('save/update an existing group')
      finalSettings.irrigation.groups = finalSettings.irrigation.groups.map(
        g => (g._id === group._id ? group : g)
      )
    } else {
      //('saving/create a new group')
      finalSettings.irrigation.groups.push(group)
    }
    // console.log('finalSettings')
    // console.log(finalSettings)

    /* find irrigationPoints that need to be reverted to the default settings */
    let defaultIrrigationPointCodes: string[] = []
    if (existingGroup) {
      defaultIrrigationPointCodes = existingGroup.irrigationPointCodes.filter(c => !newIrrigationPointCodes.includes(c))
    }

    /*
    * Fetch affected irrigationPoints and station controllers.
    */
    /* fetch affected irrigationPoints */
    let affectedIrrigationPointCodes = newIrrigationPointCodes.concat(defaultIrrigationPointCodes)
    let affectedIrrigationPoints: any [] = switches
    .filter(i => affectedIrrigationPointCodes.includes(i.properties.code))
    .filter(i => !!i.properties.stationCode)//fetch only irrigation points with an associated station
    if (!affectedIrrigationPoints || affectedIrrigationPoints.length === 0) {
      return templateService.saveEntity(finalSettings)
    }
    /* fetch affected irrigation station controllers */
    let affectedStationCodes = affectedIrrigationPoints.map(i => i.properties.stationCode)
    let affectedStationControllers: any [] = assets //TODO: get controllers from device links
    .filter(a => 
      (a.category === 'irriCtrl' || a.category === 'irriHybridCtrl' || a.category === 'irriCtrlLoRaWan') //TODO: do we need irriCtrlLoRaWan here??
      && affectedStationCodes.includes(a.stationCode)
      && (a.listensToUrl || a.mqttBroker)
    )
    if (!affectedStationControllers || affectedStationControllers.length === 0) {
      return templateService.saveEntity(finalSettings)
    }

    /* now, for each affected station controller, get its previous settings
    * and update it either with the group value or the default value */
    let promises: any[] = []
    let unreachableIrrigationPoints: any[] = []
    affectedStationControllers.forEach(async(a: any) => {
      let irriCommand: any = {
        stationCode: a.stationCode,
        macAddress: a.macAddress,
        command: 'GetSettings',
      }
      /* (note that we call sendIrriCommand instead of saveEntity cause we do not want to log these 'GetSettings' commands) */
      let promise = irriCommandService.sendIrriCommand(irriCommand)
      .then(
        resGet => {
          // let resGet = await promise
          let irrigationPoint = affectedIrrigationPoints.find(i => i.properties.stationCode === a.stationCode)
          if (resGet.response && typeof resGet.response === 'object') {
            // let stationSettings: any = resGet.response
            let stationSettings: any = {}

            /* update settings either with the group value or the default value */
            if (defaultIrrigationPointCodes.includes(irrigationPoint.properties.code)) {
              stationSettings[groupSettingsToPlcSettings[group.type]] = defaultSettings[group.type]
            } else {
              stationSettings[groupSettingsToPlcSettings[group.type]] = group.value
            }

            let irriCommand: any = {
              stationCode: a.stationCode,
              macAddress: a.macAddress,
              command: 'SetSettings',
              operatorId: profile?.user_id,
            }
            irriCommand.settings = {...stationSettings}
            /* (note that we call saveEntity instead of sendIrriCommand cause we want to log these 'SetSettings' commands) */
            return irriCommandService.saveEntity(irriCommand)
          } else {
            unreachableIrrigationPoints.push(irrigationPoint.properties.label)
          }
        }
      )
      promises.push(promise)
    })
    await Promise.all(promises)
    // let results = await Promise.allSettled(promises)
    // console.log(`saveIrrigationGroup Promise.allSettled results: ${JSON.stringify(results,null,2)}`)
    return templateService.saveEntity(finalSettings)
    .then(
      res => {
        if (unreachableIrrigationPoints.length > 0) {
          let errmsg = `\n${i18n.t('userSettings.warning')}\n${i18n.t('userSettings.warning.unreachable')}:\n${unreachableIrrigationPoints.join(', ')}`
          return Promise.reject(errmsg)
        }
        return res
      },
    )
  }

  /**
  * Deletes a given group of irrigation settings
  * and sends appropriate 'setSettings' commands to the affected irrigation station controllers.
  * The affected PLCs are the ones stated by the group.irrigationPointCodes field.
  * For each affected irrigation station controller, the function:
  * retrieves (by sending a 'GetSettings' command) its current settings,
  * updates the specific setting, stated by the group.type field,
  * and then sends a 'SetSettings' command with the updated settings.
  * @param group_id - the _id field of the given group of irrigation settings.
  */
  const deleteIrrigationGroup = async (group_id: string) => {

    let existingGroup: any = tenantSettings?.irrigation.groups.find(g => g._id === group_id)

    let finalSettings: any = _.cloneDeep(tenantSettings)
    finalSettings.irrigation.groups = finalSettings.irrigation.groups.filter(group => group._id !== group_id)

    /* find irrigationPoints that need to be reverted to the default settings */
    let defaultIrrigationPointCodes: string[] = []
    if (existingGroup) {
      defaultIrrigationPointCodes = existingGroup.irrigationPointCodes
    }

    /*
    * Fetch affected irrigationPoints and station controllers.
    */
    /* fetch affected irrigationPoints */
    let affectedIrrigationPoints: any [] = switches
    .filter(i => defaultIrrigationPointCodes.includes(i.properties.code))
    .filter(i => !!i.properties.stationCode)//fetch only irrigation points with an associated station
    if (!affectedIrrigationPoints || affectedIrrigationPoints.length === 0) {
      return templateService.saveEntity(finalSettings)
    }
    /* fetch affected irrigation station controllers */
    let affectedStationCodes = affectedIrrigationPoints.map(i => i.properties.stationCode)
    let affectedStationControllers: any [] = assets //TODO: get controllers from device links
    .filter(a => 
      (a.category === 'irriCtrl' || a.category === 'irriHybridCtrl' || a.category === 'irriCtrlLoRaWan') //TODO: do we need irriCtrlLoRaWan here??
      && affectedStationCodes.includes(a.stationCode)
      && (a.listensToUrl || a.mqttBroker)
    )
    if (!affectedStationControllers || affectedStationControllers.length === 0) {
      return templateService.saveEntity(finalSettings)
    }

    /* now, for each affected station controller, get its previous settings
    * and update it either with the group value or the default value */
    let promises: any[] = []
    let unreachableIrrigationPoints: any[] = []
    affectedStationControllers.forEach(async(a: any) => {
      let irriCommand: any = {
        stationCode: a.stationCode,
        macAddress: a.macAddress,
        command: 'GetSettings',
      }
      /* (note that we call sendIrriCommand instead of saveEntity cause we do not want to log these 'GetSettings' commands) */
      let promise = irriCommandService.sendIrriCommand(irriCommand)
      .then(
        resGet => {
          // let resGet = await promise
          if (resGet.response && typeof resGet.response === 'object') {
            let stationSettings: any = resGet.response

            /* update settings either with the group value or the default value */
            stationSettings[groupSettingsToPlcSettings[existingGroup.type]] = defaultSettings[existingGroup.type]

            let irriCommand: any = {...stationSettings}
            irriCommand.stationCode = a.stationCode
            irriCommand.command = 'SetSettings'
            /* (note that we call saveEntity instead of sendIrriCommand cause we want to log these 'SetSettings' commands) */
            return irriCommandService.saveEntity(irriCommand)
          } else {
            let irrigationPoint = affectedIrrigationPoints.find(i => i.properties.stationCode === a.stationCode)
            unreachableIrrigationPoints.push(irrigationPoint.properties.label)
          }
        }
      )
      promises.push(promise)
    })
    await Promise.all(promises)
    return templateService.saveEntity(finalSettings)
    .then(
      res => {
        if (unreachableIrrigationPoints.length > 0) {
          let errmsg = `\n${i18n.t('userSettings.warning')}\n${i18n.t('userSettings.warning.unreachable')}:\n${unreachableIrrigationPoints.join(', ')}`
          return Promise.reject(errmsg)
        }
        return res
      },
    )
  }

  return({
    getEntities: templateService.getEntities,
    saveEntity: templateService.saveEntity,
    deleteEntity: templateService.deleteEntity,
    saveIrrigationGroup: saveIrrigationGroup,
    deleteIrrigationGroup: deleteIrrigationGroup,
  })

}

export default TenantSettingsService
