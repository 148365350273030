import { useCollectionStore } from '../state/collectionStore'
import { useCommonStore } from '../state/commonStore'
import { useUiStore } from '../state/uiStore'
import useServices from '../hooks/useServices'
import NotificationsManager from '../services/NotificationsManager'

const useNavigation = () => {
  const services = useServices()
  const notificationsManager = NotificationsManager()

  const agriculturalPeriods = useCollectionStore(state => state.agriculturalPeriods)

  const profile = useCommonStore(state => state.profile)
  const currentAgriculturalPeriod = useCommonStore(state => state.currentAgriculturalPeriod)

  const setPartialState = useUiStore(state => state.setPartialState)
  const entitiesOverlayKey = useUiStore(state => state.entitiesOverlayKey)

  const verticalNavigate = (key: string, doNotSetContentKey?: boolean) => {
    // console.log(`verticalNavigate key ${key}`)

    /* Set navigation keys for 1st and 2nd level */
    let navKeyLev1: string = ''
    let navKeyLev2: string = 'default'
    if (key) {
      let keyParts = key.split('/')
      navKeyLev1 = keyParts[0]
      if (keyParts.length > 1) {
        navKeyLev2 = keyParts[1]
      }
    }

    let navPromise: any = Promise.resolve('navOK')
    let newNavState: any = {
      entitiesOpen: false,
      entitiesOverlayOpen: false,
      entitiesOverlayKey: '',
      isFeatureDetailsDocked: false,
      isAddNetworkFromCons: false,
      featureDetailsTab: 'properties',
    }
    if (!doNotSetContentKey) {
      newNavState.contentKey = navKeyLev1
      newNavState.contentKeyLev2 = navKeyLev2
    }
    switch (navKeyLev1) {
      case 'entities':
        newNavState.entitiesOpen = true
        break
      case 'equipmentmaintenance':
        navPromise = actEquipmentMaintenance()
        break
      case 'inventorycategories':
        navPromise = actInventoryCategories()
        break
      case 'activities':
        navPromise = actActivities()
        break
      case 'waterbalance':
        newNavState.isFeatureDetailsDocked = true //TODO: maybe we do not need this, check if it really does something
        navPromise = actWaterBalance()
        break
      case 'qualityControl':
        navPromise = actQualityControl()
        break
      case 'energyoptimization':
        break
      case 'hydraulicoptimization':
        break
      case 'scada':
        break
      case 'catalogs':
        newNavState.isFeatureDetailsDocked = true
        navPromise = actCatalogs()
        break
      case 'irrigation':
        newNavState.isFeatureDetailsDocked = true
        const doReloadCtrls = true
        navPromise = actIrrigation(doReloadCtrls)
        break
      case 'irriForm':
        newNavState.isFeatureDetailsDocked = true
        navPromise = actIrriForm()
        break
      case 'irriSchedule':
        newNavState.isFeatureDetailsDocked = true
        navPromise = actIrriSchedule()
        break
      case 'consumerPortal':
        navPromise = actConsumerPortal()
        break
      case 'cp.myAccount':
        navPromise = actConsumerPortal()
        break
      case 'cp.report':
        navPromise = actConsumerPortal()
        break
      case 'cp.network':
        navPromise = actConsumerPortal()
        break
      case 'dataImport':
        break
      case 'NOTIFICATIONS':
        newNavState.contentKey = 'entities'
        newNavState.entitiesOpen = (entitiesOverlayKey === 'NOTIFICATIONS' ? true : false)
        newNavState.entitiesOverlayOpen = (entitiesOverlayKey === 'NOTIFICATIONS' ? false : true)
        newNavState.entitiesOverlayKey = (entitiesOverlayKey === 'NOTIFICATIONS' ? '' : 'NOTIFICATIONS')
        break
      case 'USER_SETTINGS_DIALOG':
        newNavState.contentKey = 'entities'
        newNavState.entitiesOpen = (entitiesOverlayKey === 'USER_SETTINGS_DIALOG' ? true : false)
        newNavState.entitiesOverlayOpen = (entitiesOverlayKey === 'USER_SETTINGS_DIALOG' ? false : true)
        newNavState.entitiesOverlayKey = (entitiesOverlayKey === 'USER_SETTINGS_DIALOG' ? '' : 'USER_SETTINGS_DIALOG')
        break
      case 'tenantSettings':
        navPromise = actTenantSettings()
        break
      case 'hydoorSettings':
        navPromise = actHydoorSettings()
        break
      //Mobile navigation for inverter app
      case 'inverterMobile':
        newNavState.isFeatureDetailsDocked = true
        const doReloadCtrls_ = true
        navPromise = actInverterMobile(doReloadCtrls_)
        break;
      default:
        break
    }
    setPartialState(newNavState)
    return navPromise
  }

  /* EquipmentMaintenance */
  const actEquipmentMaintenance = () => {
    return Promise.all([
      services.personnelService.getEntities()
    ])
  }
  /* InventoryCategories */
  const actInventoryCategories = () => {
    return Promise.all([
      services.inventoryItemService.getInventoryItems(),
      services.personnelService.getEntities(),
    ])
  }
  /* Activities */
  const actActivities = () => {
    return Promise.all([
      services.activityService.getEntities(),
      services.procedureService.getEntities(),
      services.personnelService.getEntities(),
    ])
  }
  /* WaterBalance */
  const actWaterBalance = () => {
    const options = {
      doReload: false,
      cachePromise: true,
    }
    return Promise.all([
      services.populationService.getEntities(),
      services.waterLossService.getEntities(),
      services.consumerService.getEntities(undefined, options)
    ])
  }
  /* QualityControl */
  const actQualityControl = () => {
    return Promise.all([
      services.indicatorService.getEntities(),
      services.personnelService.getEntities(),
    ])
  }
  /* Catalogs */
  const actCatalogs = () => {
    let filter = undefined
    const options = {
      doReload: false,
      cachePromise: true,
    }
    return Promise.all([
      services.personnelService.getEntities(),
      services.consumerService.getEntities(filter, options),
      services.assetService.getEntities(filter, options),
      services.deviceLinkService.getEntities(filter, options),
      services.groupItemService.getEntities(filter, options),
      services.mseriesService.getEntities(filter, options),
      services.mseriesLinkService.getEntities(filter, options),
      services.cultivationService.getEntities(filter, options),
    ])
  }
  /* irriSchedule */
  const actIrriSchedule = async () => {
    let filter = undefined
    const options = {
      doReload: false,
      cachePromise: true,
    }
    let agriculturalPeriodFilter = {
      cultivationPeriod: currentAgriculturalPeriod?.cultivationPeriod
    }
    if (!agriculturalPeriods || !agriculturalPeriods.length || !currentAgriculturalPeriod) {
      const agriPeriods = await services.agriculturalPeriodService.getEntities(filter, options)
      const currentAgPeriod = agriPeriods?.data?.find(ap => ap.isVisible === true)
      agriculturalPeriodFilter.cultivationPeriod = currentAgPeriod?.cultivationPeriod || -1
    }
    return Promise.all([
      services.switchSlotService.getEntities(filter, options),
      services.consumerService.getEntities(filter, options),
      services.cultivationService.getEntities(agriculturalPeriodFilter, options),
      services.irriSchedService.getEntities(agriculturalPeriodFilter, options),
      services.irriProgService.getEntities(agriculturalPeriodFilter, options),
      services.assetService.getEntities(filter, options),
      services.agriculturalPeriodService.getEntities(filter, options),
      /* irriCommands and irriEvents use the cultivationPeriod year (eg 2023) to create the cacheKey,
      the cultivationPeriod is passed as a property inside the options parameter */
      services.irriEventService.getEntities(filter, {
        ...options,
        cultivationPeriod: currentAgriculturalPeriod?.cultivationPeriod,
      }),
    ])
  }
  /* Irrigation */
  const actIrrigation = async (doReloadCtrls?: boolean) => {
    let filter = undefined
    const options = {
      doReload: false,
      cachePromise: true,
    }
    let agriculturalPeriodFilter = {
      cultivationPeriod: currentAgriculturalPeriod?.cultivationPeriod
    }
    /**The agriculturalPeriods hasn't been loaded at this point
     * but it is neccessary in order to setup the agriculturalPeriodFilter (cultivationPeriod is mandatory)
     * so before firing Promise.all the agriculturalPeriods must be fetched */
    if (!agriculturalPeriods || !agriculturalPeriods.length || !currentAgriculturalPeriod) {
      const agriPeriods = await services.agriculturalPeriodService.getEntities(filter, options)
      const currentAgPeriod = agriPeriods?.data?.find(ap => ap.isVisible === true)
      /**filter gets fizzled if the the value is undefined, so in case there is no currentAgriculturalPeriod
       * set the cultivationPeriod to -1 to hide the documents */
      agriculturalPeriodFilter.cultivationPeriod = currentAgPeriod?.cultivationPeriod || -1
    }
    return Promise.all([
      services.assetService.getEntities(filter, options),
      services.assetService.getIrriCtrlsState({ doReload: doReloadCtrls }),
      services.assetService.getInvertersState({ doReload: doReloadCtrls }),
      services.agriculturalPeriodService.getEntities(filter, options),
      services.switchSlotService.getEntities(filter, options),
      services.consumerService.getEntities(filter, options),
      services.cultivationService.getEntities(agriculturalPeriodFilter, options),
      /* irriCommands and irriEvents use the cultivationPeriod year (eg 2023) to create the cacheKey,
      the cultivationPeriod is passed as a property inside the options parameter */
      services.irriCommandService.getEntities(filter, {
        ...options,
        cultivationPeriod: currentAgriculturalPeriod?.cultivationPeriod,
      }),
      services.irriEventService.getEntities(filter, {
        ...options,
        cultivationPeriod: currentAgriculturalPeriod?.cultivationPeriod,
      }),
      services.irriProgService.getEntities(agriculturalPeriodFilter, options),
      services.tenantSettingsService.getEntities(filter, options),
      services.personnelService.getEntities(filter),
      services.irriSchedService.getEntities(agriculturalPeriodFilter, options),
      services.groupItemService.getEntities(filter, options),
      services.deviceLinkService.getEntities(filter, options),
      services.mseriesService.getEntities(filter, options),
      services.mseriesLinkService.getEntities(filter, options),
    ])
  }
  /* InverterMobile */
  const actInverterMobile = async (doReloadCtrls?: boolean) => {
    let filter = undefined
    const options = {
      doReload: false,
      cachePromise: true,
    }
    let agriculturalPeriodFilter = {
      cultivationPeriod: currentAgriculturalPeriod?.cultivationPeriod
    }
    /**The agriculturalPeriods hasn't been loaded at this point
     * but it is neccessary in order to setup the agriculturalPeriodFilter (cultivationPeriod is mandatory)
     * so before firing Promise.all the agriculturalPeriods must be fetched */
    if (!agriculturalPeriods || !agriculturalPeriods.length || !currentAgriculturalPeriod) {
      const agriPeriods = await services.agriculturalPeriodService.getEntities(filter, options)
      const currentAgPeriod = agriPeriods?.data?.find(ap => ap.isVisible === true)
      /**filter gets fizzled if the the value is undefined, so in case there is no currentAgriculturalPeriod
       * set the cultivationPeriod to -1 to hide the documents */
      agriculturalPeriodFilter.cultivationPeriod = currentAgPeriod?.cultivationPeriod || -1
    }
    return Promise.all([
      services.assetService.getEntities(filter, options),
      services.assetService.getIrriCtrlsState({ doReload: doReloadCtrls }),
      services.assetService.getInvertersState({ doReload: doReloadCtrls }),
      services.agriculturalPeriodService.getEntities(filter, options),
      // services.switchSlotService.getEntities(filter, options),
      services.consumerService.getEntities(filter, options),
      services.cultivationService.getEntities(agriculturalPeriodFilter, options),
      /* irriCommands and irriEvents use the cultivationPeriod year (eg 2023) to create the cacheKey,
      the cultivationPeriod is passed as a property inside the options parameter */
      services.irriCommandService.getEntities(filter, {
        ...options,
        cultivationPeriod: currentAgriculturalPeriod?.cultivationPeriod,
      }),
      services.irriEventService.getEntities(filter, {
        ...options,
        cultivationPeriod: currentAgriculturalPeriod?.cultivationPeriod,
      }),
      services.irriProgService.getEntities(agriculturalPeriodFilter, options),
      services.tenantSettingsService.getEntities(filter, options),
      services.personnelService.getEntities(filter),
      // services.irriSchedService.getEntities(agriculturalPeriodFilter, options),
    ])
  }
  /* IrriForms */
  const actIrriForm = async () => {
    let filter = undefined
    const options = {
      doReload: false,
      cachePromise: true,
    }
    let agriculturalPeriodFilter = {
      cultivationPeriod: currentAgriculturalPeriod?.cultivationPeriod
    }
    if (!agriculturalPeriods || !agriculturalPeriods.length || !currentAgriculturalPeriod) {
      const agriPeriods = await services.agriculturalPeriodService.getEntities(filter, options)
      const currentAgPeriod = agriPeriods?.data?.find(ap => ap.isVisible === true)
      agriculturalPeriodFilter.cultivationPeriod = currentAgPeriod?.cultivationPeriod || -1
    }
    return Promise.all([
      services.switchSlotService.getEntities(filter, options),
      services.consumerService.getEntities(filter, options),
      services.cultivationService.getEntities(agriculturalPeriodFilter, options),
      services.irriFormService.getEntities(agriculturalPeriodFilter, options),
      services.agriculturalPeriodService.getEntities(filter, options),
    ])
  }
  /* ConsumerPortal */
  const actConsumerPortal = () => {
    const getNotifications = () => {
      if (!profile?.consumer) {
        return notificationsManager.loadConsumerNotificationsForOperator()
      }
    }
    const options = {
      doReload: false,
      cachePromise: true,
    }
    return Promise.all([
      services.niamService.getTenants(),
      services.consumerService.getEntities(undefined, options),
      getNotifications(),
      services.switchSlotService.getEntities(undefined, options),
    ])
  }
  /** TenantSettings */
  const actTenantSettings = async () => {
    let filter = undefined
    const options = {
      doReload: false,
      cachePromise: true,
    }
    let agriculturalPeriodFilter = {
      cultivationPeriod: currentAgriculturalPeriod?.cultivationPeriod
    }
    if (!agriculturalPeriods || !agriculturalPeriods.length || !currentAgriculturalPeriod) {
      const agriPeriods = await services.agriculturalPeriodService.getEntities(filter, options)
      const currentAgPeriod = agriPeriods?.data?.find(ap => ap.isVisible === true)
      agriculturalPeriodFilter.cultivationPeriod = currentAgPeriod?.cultivationPeriod || -1
    }
    return Promise.all([
      services.assetService.getEntities(filter, options),
      services.deviceLinkService.getEntities(filter, options),
      services.groupItemService.getEntities(filter, options),
      services.agriculturalPeriodService.getEntities(filter, options),
      services.cultivationService.getEntities(agriculturalPeriodFilter, options),
      services.irriProgService.getEntities(agriculturalPeriodFilter, options),
      // services.tenantSettingsService.getEntities(undefined, options)
    ])
  }
  /** HydoorSettings */
  const actHydoorSettings = () => {
    let filter = undefined
    const options = {
      doReload: false,
      cachePromise: true,
    }
    return Promise.all([
      services.assetService.getEntities(filter, options),
      services.groupItemService.getEntities(filter, options),
      services.deviceLinkService.getEntities(filter, options),
    ])
  }

  return {
    verticalNavigate: verticalNavigate,
  }

}

export default useNavigation